export default {
  props: {
    updateData: {
      type: Object,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
}
